<template>
  <div class="news-item">
    <el-image alt="" class="thumbnail" :lazy="true" :src="item.image" />
    <div class="info">
      <div class="title">{{ item.title }}</div>
      <div class="desc">{{ item.desc }}</div>
      <div class="date">{{ item.date }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NewsItem",
  props: {
    item: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped>

.news-item {
  display: flex;
  align-items: center;
  padding: 20px;
  transition: all .2s
}

.news-item:hover {
  background-color: #fafafa
}

.news-item .thumbnail{
  min-width: 350px;
  width: 350px;
  height: 170px;
  -o-object-fit: contain;
  object-fit: contain;
  cursor: pointer
}

.news-item .info {
  margin-left: 30px;
  line-height: 1.5
}

.news-item .title {
  text-align: left;
  font-size: 20px;
  font-weight: 500;
  cursor: pointer;
  transition: color .2s
}

.news-item .title:hover {
  color: var(--primary-hover-color)
}

.news-item .desc,.news-item .date {
  color: var(--weak-text-color)
}

.news-item .desc {
  margin: 10px 0 20px
}

.news-item .date {
  font-size: 12px;
  font-weight: 500;
  text-align: left;
}
</style>
