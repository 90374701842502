<template>
  <div class="news">
    <banner
        banner1="https://n.tobidad.com/site/videos/news/banner1.mp4?v=1.0.1"
        banner2="https://n.tobidad.com/site/videos/news/banner2.mp4?v=1.0.1"
        text="资讯活动"
        style="height: 500px;"
    />

    <div class="list">
      <NewsItem v-for="item in list" :key="item" :item="item"></NewsItem>
    </div>
  </div>
</template>

<script>
import NewsItem from "@/components/Card/NewsItem";
import Banner from "@/components/Card/Banner";

export default {
  name: 'News',
  components: {
    Banner,
    NewsItem,
  },
  data() {
    return {
      list: [
        {
          image: 'https://n.tobidad.com/site/images/news/20240109.jpg',
          title: '变现干货：解锁广告变现收益，探求App商业化新视角｜活动报名',
          desc: '大会现场，ToBid聚合将就商业化变现大盘趋势、广告变现痛点、解决方案等维度，与在座各位同仁，进行深刻的洞察分析与发展探讨',
          date: '2024-01-09',
        },
        {
          image: 'https://n.tobidad.com/site/images/news/20231218.jpg',
          title: '聚焦商业化｜影音类APP如何吃到广告变现的红利',
          desc: '打破影音类APP商业化变现僵局，实现可持续发展，产出更大的商业价值，是影音类开发者需要思考和解决的问题。',
          date: '2023-12-18',
        },
        {
          image: 'https://n.tobidad.com/site/images/news/20231201.jpg?v=2',
          title: '一路驰骋，随十起跑｜《地铁跑酷》用十年书写长期主义运营之道！',
          desc: '地铁跑酷，十周年生日快乐！',
          date: '2023-12-01',
        },
        {
          image: 'https://n.tobidad.com/site/images/news/20231107.jpg',
          title: '聚焦 | 出行类App变现痛点全析及收益提升法则',
          desc: '在这个竞争激烈、用户需求多样化且市场份额有限的领域里，如何让出行类App获得持续稳定的收益成为了每位开发者都需要思考和解决的问题！',
          date: '2023-11-07',
        }
      ]
    }
  },
}
</script>

<style>
.news{
  min-width: 1440px
}

.news .list {
  width: 80%;
  margin: 20px auto;
}
</style>
